/* eslint-disable jsx-a11y/iframe-has-title */
import './index.scss';

const ContactsScreen = () => {
  return (
    <section className="contacts" id="contacts">
    <h2 className="contacts__heading">Контакты</h2>
    <div className="contacts__box">
      <div className="contacts__box__info">
        <p className="contacts__box__info__text">В офисе прием осуществляется по предварительной записи:</p>
        <p className="contacts__box__info__text">Саратов, ул. Большая Казачья, д. 80</p>
        <a className="contacts__box__info__link" href="tel:+79272774195">+7 927 277 41 95</a>
        <a className="contacts__box__info__link" href="mailto:orson@inbox.ru">orson@inbox.ru</a>
        {/* <iframe className="contacts__box__info__map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A96da1cfd0e1868f3bfe98e794f14698452b26d34f3d5d544289d0a09541c6a65&amp;source=constructor" frameborder="0"></iframe> */}
        <iframe className="contacts__box__info__map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A8ca953146e852cd03c1ed8874979840c0a5c10731af396d831ea617977cea750&amp;source=constructor" frameborder="0"></iframe>
      </div>
      <div className="contacts__box__formBox">
        <p className="contacts__box__formBox__text margin-bottom-medium">
          Если Вам нужна моя помощь или консультация, вы можете связаться со мной через форму обратной связи или любым удобным для вас способом.
        </p>

        <form className="contacts__box__formBox__form margin-bottom-small" method="post" action="php/send_mail.php">
          <input className="contacts__box__formBox__form__inp" type="text" placeholder="Ваше имя..." name="name" required />
          <input className="contacts__box__formBox__form__inp" type="text" placeholder="Ваше e-mail или номер телефона..." name="contact" required />
          <textarea className="contacts__box__formBox__form__txtarea" name="text" placeholder="Ваше сообщение...." required></textarea>
          <button className="contacts__box__formBox__form__btn" type="submit">Отправить</button>
        </form>
        <p className="contacts__box__formBox__desc">
          Нажимая кнопку “Отправить” Вы соглашаетесь с <a className="contacts__box__formBox__desc__link" href="policy.html" target="_blank">“Политикой
          обработки персональных данных”</a> на сайте
        </p>
      </div>
    </div>
  </section>
  )
}

export default ContactsScreen;
