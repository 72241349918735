import CloseIco from '../../../../assets/close.svg';
import './index.scss';

const PhisicsModal = ({ handleClose }) => {
  return (
    <div className="overlay">
      <div className="modal">
      <img src={CloseIco} alt="CloseBtn" className='closeBtn' onClick={() => handleClose()} />
      <main className="aboutAdv">
    <p className="servicePage__text">
      Адвокат Рыжова Наталья Викторовна оказывает услуги по
      представлению интересов в судах по гражданским делам, в том числе по семейным, жилищным,
      наследственным спорам, делам по разрешению экономических споров. Услуга включает в себя
      изучение адвокатом материалов дела, формирование правовой позиции по делу, составление
      искового заявления (административного иска), составление встречного иска, возражений, отзыва
      на исковое заявление, апелляционной и кассационной жалобы. 
      <br/><br/>
      Адвокаты непосредственно
      участвует в судебных заседаниях, в судах общей юрисдикции, в арбитражных судах, в судах
      апелляционной инстанции, осуществляет представление интересов в судах кассационной
      инстанции, в том числе в Первом кассационном суде общей юрисдикции.
      <br/><br/>
      Адвокатом предоставляется квалифицированные устные правовые консультации по различным
      вопросам гражданского, семейного, жилищного права, осуществляется подготовка правовых
      заключений по вопросам, требующих правового разъяснения, проводится правовая экспертиза
      договоров и иных документов.
    </p>
    <h3 className="servicePage__heading">Семейные споры.</h3>
    <div className="servicePage__text">
      Семейные споры это самая распространенная проблема в современной жизни. Очень редко супругам удается достигнуть компромисса 
      при разделе имущества, решении вопроса с кем из родителей будут проживать дети, размера денежных средств на содержание детей. 
      Причин тому достаточно — это и взаимные обиды и внутренние семейные конфликты, которые в конечном итоге приводят к затяжным судебным 
      разбирательствам.
      <br />
      <br />
      В этот сложный период в жизни адвокат окажет юридическую помощь в составлении необходимых юридических документов и представлении 
      ваших интересов в суде.
      <br />
      <br />
      Представим ваши интересы по делам: 
      <ul className="servicePage__list">
        <li className="servicePage__list__item">О расторжении брака;</li>
        <li className="servicePage__list__item">Раздела имущества супругов;</li>
        <li className="servicePage__list__item">Взыскание алиментов;</li>
        <li className="servicePage__list__item">Защита прав несовершеннолетних;</li>
        <li className="servicePage__list__item">Определение места жительства детей;</li>
        <li className="servicePage__list__item">Определение порядка общения с ребенком;</li>
        <li className="servicePage__list__item">Нечинение препятствий в общении с ребенком как родителей, так и других родственников;</li>
        <li className="servicePage__list__item">Лишение и ограничение родительских прав;</li>
        <li className="servicePage__list__item">Признание отцовства (материнства).</li>
      </ul>

    </div>
    <h3 className="servicePage__heading">Интеллектуальное право.</h3>
    <div className="servicePage__text">
      Результаты интеллектуальной деятельности и средства индивидуализации являются
      интеллектуальной собственностью, которым законом предоставляется правовая охрана.
      <br /><br />
      К объектам интеллектуальных прав относятся:
      <ul className="servicePage__list">
        <li className="servicePage__list__item">Авторское право;</li>
        <li className="servicePage__list__item">Права, смежные с авторскими;</li>
        <li className="servicePage__list__item">Патентное право;</li>
        <li className="servicePage__list__item">Нетрадиционные объекты интеллектуальной собственности ( секреты производства ноу-
хау, селекционные достижения, топологии интегральных микросхем);</li>
        <li className="servicePage__list__item">Средства индивидуализации индивидуальных предпринимателей и юридических лиц.</li>
      </ul>
      <br />
      В соответствии с ГК РФ защита исключительных прав на результаты интеллектуальной
      деятельности осуществляется путем признания права, о пресечении действий, нарушающих право
      - к лицу, совершающими такие действия, путем возмещения убытков.
      <br /><br />
      В случае нарушения прав автора их защита осуществляется, в частности, путем признания
      права, восстановления положения, существовавшего до нарушения права, пресечения действий,
      нарушающих право или создающих угрозу его нарушения, компенсации морального вреда,
      публикации решения суда о допущенном нарушении (ст.1251 ГК РФ).
    </div>
    <h3 className="servicePage__heading">Жилищные споры.</h3>
    <div className="servicePage__text">
      В жизни каждого человека могут возникнуть жилищные вопросы, которые возможно разрешить только в судебном порядке.
      <br /><br/>
      Юридические услуги по вопросам жилищного права включают в себя:
      <ul className="servicePage__list">
        <li className="servicePage__list__item">Порядок пользования квартирой, находящейся в общей долевой собственности;</li>
        <li className="servicePage__list__item">Выдел доли в жилом помещении при разделе совместно-нажитого имущества;</li>
        <li className="servicePage__list__item">Вселение и выселение;</li>
        <li className="servicePage__list__item">Долевое участие в строительстве;</li>
        <li className="servicePage__list__item">Заключение договора мены;</li>
        <li className="servicePage__list__item">Заключение договора дарения жилого помещения;</li>
        <li className="servicePage__list__item">Иные жилищные споры;</li>
        <li className="servicePage__list__item">Составление исковых заявлений, возражения на исковое заявление, ходатайства и необходимые процессуальные документы;</li>
        <li className="servicePage__list__item">Представление интересов Доверителя в судебном заседании при рассмотрении гражданского дела;</li>
        <li className="servicePage__list__item">Участие в апелляционной, кассационной инстанции при обжаловании судебных актов;</li>
      </ul>
    </div>
    <h3 className="servicePage__heading">Наследственное право.</h3>
    <div className="servicePage__text">
      В соответствии со статьей 1110 Гражданского кодекса РФ при наследовании имущество умершего (наследство, наследственное имущество) 
      переходит к другим лицам в порядке универсального правопреемства, то есть в неизменном виде как единое целое и в один и тот же момент, 
      если из правил настоящего Кодекса не следует иное.
      <br /><br />
      По вопросам вступления в наследство и оспаривания наследственных прав Адвокат оказывает следующие виды услуг:
      <ul className="servicePage__list">
        <li className="servicePage__list__item">Консультирование по вопросам вступления в наследство;</li>
        <li className="servicePage__list__item">Оказание юридической помощи при вступлении в наследство;</li>
        <li className="servicePage__list__item">Признание права собственности на долю в наследственном имуществе;</li>
        <li className="servicePage__list__item">Обжалование действий (бездействия) нотариуса при оформлении наследства;</li>
        <li className="servicePage__list__item">Восстановление пропущенных сроков для принятия наследства;</li>
        <li className="servicePage__list__item">Признание завещания недействительным;</li>
        <li className="servicePage__list__item">Иные наследственные споры.</li>
      </ul>
    </div>
    <h3 className="servicePage__heading">Земельные споры.</h3>
    <div className="servicePage__text">
      Чаще всего земельные споры возникают при определении границ земельного участка, при признании права собственности 
      на земельный участок или его часть, изъятие земельного участка из чужого незаконного владения.
      <br /><br />
      Адвокат по земельным спорам поможет вам профессионально оказать юридическую помощь при возникновении конфликтной ситуации, 
      связанной с владением, пользованием и распоряжением земельного участка.
      <br /><br />
      Услуги адвоката по земельным спорам:
      <ul className="servicePage__list">
        <li className="servicePage__list__item">Признание права собственности на земельный участок;</li>
        <li className="servicePage__list__item">Разрешение конфликтных ситуаций, связанных с владением земельными участками;</li>
        <li className="servicePage__list__item">Заключением договоров купли-продажи земельного участка;</li>
        <li className="servicePage__list__item">Отказом в регистрации права собственности на землю;</li>
        <li className="servicePage__list__item">Оспариванием права пользования земельным участком;</li>
        <li className="servicePage__list__item">Споры по установлению границ земельного участка;</li>
        <li className="servicePage__list__item">Спорами из-за конфискации (изъятием для государственных нужд) земли;</li>
        <li className="servicePage__list__item">Изменение категории земельного участка;</li>
        <li className="servicePage__list__item">Наследование земельных участков;</li>
        <li className="servicePage__list__item">Признание сделок купли-продажи, мены, дарения и др. земельных участков и земельных паев недействительными.</li>
      </ul>
    </div>
    {/* <h3 className="servicePage__heading">Уголовные дела.</h3>
    <div className="servicePage__text">
      Добиться строгого исполнения закона при производстве уголовного дела, исключить возможность ложного обвинения, 
      защитить права подозреваемых, обвиняемых, потерпевших при рассмотрении уголовного дела в суде вам поможет 
      квалифицированный специалист, имеющий необходимый опыт в уголовном судопроизводстве.
      <br /><br />
      Применяя все возможные правовые инструменты адвокат поможет исключить в ряде случаев незаконное привлечение к уголовной 
      ответственности и вынесения справедливого приговора.
    </div> */}
  </main>
      </div>
    </div>
  )
}

export default PhisicsModal;
