import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Index from './pages/index';
// import PostsPage from './pages/posts';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Index />,
    },
    // {
    //   path: "/posts",
    //   element: <PostsPage />
    // }
  ]); 
  return (
    <RouterProvider router={router} />
  );
}

export default App;
