import Diploma1Img from '../../../../assets/img/other/dip1.png';
import Diploma2Img from '../../../../assets/img/other/dip2.png';
import Diploma3Img from '../../../../assets/img/other/dip3.png';
import Diploma4Img from '../../../../assets/img/other/dip4.jpeg';
import './index.scss';

const AboutScreen = ({ toggleAbout }) => {
  return (
    <section className="about">
    <h2 className="about__heading">Об адвокате</h2>
    <div className="about__infoBox">
      <div className="about__infoBox__info">
        <p className="about__infoBox__info__text">
          Имею большой положительный опыт разрешения судебных споров в арбитражных судах, судах общей юрисдикции, на
          всех стадиях судебного процесса, в том числе судах города Саратова и Саратовской области, Верховном Суде РФ.
        </p>
        <p className="about__infoBox__info__text">
          Успешно проводились судебные процессы по спорам о регистрации права собственности по инвестиционным
          договорам жилищного строительства.
        </p>
        <p className="about__infoBox__info__text">
          За многолетний труд и защиту прав граждан награждена почетными грамотами
          и благодарностью.
        </p>
        <button onClick={toggleAbout} className="about__infoBox__info__link">Подробнее...</button>
      </div>
    </div>

    <div className="about__diploms">
      <div className="about__diploms__item">
        <img className="about__diploms__item__pic1" src={Diploma1Img} alt="diplom" />
      </div>
      <div className="about__diploms__item">
        <img className="about__diploms__item__pic2" src={Diploma2Img} alt="diplom" />
        <img className="about__diploms__item__pic2" src={Diploma4Img} alt="diplom" />
      </div>
      <div className="about__diploms__item">
        <img className="about__diploms__item__pic1" src={Diploma3Img} alt="diplom" />
      </div>
    </div>
  </section>
  )
}

export default AboutScreen;
