import TGIcon from '../../../../assets/tg-ico.svg';

import './index.scss';

const TGScreen = () => {
  return (
    <div className="tgScreen">
      <div className="tgScreen__icoBox">
        <a href='https://t.me/rnv_adv' target='_blanc'><img src={TGIcon} alt="CloseBtn" className='tgScreen__icoBox__ico2'/></a>
        {/* <a href='https://t.me/rnv_adv' target='_blanc'><img src={TGIcon} alt="CloseBtn" className='tgScreen__icoBox__ico3'/></a> */}
        <a href='https://t.me/rnv_adv' target='_blanc'><img src={TGIcon} alt="CloseBtn" className='tgScreen__icoBox__ico'/></a>
      </div>
      <div className="tgScreen__textBox">
        Подписывайтесь на телеграм канал. Там вы найдете много полезной информации юридического характера.
      </div>
    </div>
  )
}

export default TGScreen;
