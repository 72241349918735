import CloseIco from '../../../../assets/close.svg';

import './index.scss';

const AboutModal = ({ handleClose }) => {
  return (
    <div className="overlay">
      <div className="modal">
      <img src={CloseIco} alt="CloseBtn" className='closeBtn' onClick={handleClose} />
        <main className="aboutAdv">
          <p className="aboutAdv__text">
            <b>Образование:</b>
            <br/>
            Саратовский юридический институт им. Д.И.Курского
            <br/><br/>
            <b>Стаж работы адвокатом с 1995 года.</b>
            <br/><br/>
            С момента адвокатской деятельности оказывает квалифицированную юридическую
            помощь гражданам и юридическим лицам, а также иностранным гражданам и компаниям в
            следующих отраслях права: гражданское, семейное, трудовое, административное,
            жилищное, медицинское.
            <br/><br/>
            Имеет большой положительный опыт разрешения судебных споров в арбитражных судах, судах общей юрисдикции, на
            всех стадиях судебного процесса, в том числе судах города Саратова и Саратовской области, Верховном Суде РФ.
            <br/><br/>
          </p>
          <div className="aboutAdv__flexBox">
            <div className="aboutAdv__flexBox__item">
              <h2 className="aboutAdv__header2">Принципы:​</h2>
              <ul className="aboutAdv__services">
                <li className="aboutAdv__services__item">
                  <b>Добросовестность</b> — не давать пустых обещаний и рассказывать о судебной
                  перспективе поставленной задачи;
                </li>
                <li className="aboutAdv__services__item">
                  <b>Надежность</b> — в процессе безупречной работы накоплен огромный опыт,
                  позволяющий предложить более оптимальный результат достижения Ваших
                  целей;
                </li>
                <li className="aboutAdv__services__item">
                  <b>Прозрачность ценовой политики</b> — стоимость услуг обсуждается в каждом
                  конкретном случае и зависит от сложности и объема дела, возможна
                  поэтапная оплата работ.
                </li>
              </ul>
            </div>

          </div>
        </main>
      </div>
    </div>
  );
}

export default AboutModal;
