import './index.scss';

const PrinciplesScreen = () => {
  return (
    <section className="principles">
          <h2 className="principles__heading">Принципы работы</h2>

          <div className="principles__box">
            <div className="principles__box__item">
              <span className="principles__box__item__fig">1</span>
              <p className="principles__box__item__text">
                <b>Добросовестность</b> — не давать пустых обещаний и рассказывать о судебной
                перспективе поставленной задачи.
              </p>
            </div>

            <div className="principles__box__item">
              <span className="principles__box__item__fig">2</span>
              <p className="principles__box__item__text">
                <b>Надежность</b> — в процессе безупречной работы накоплен огромный опыт,
                позволяющий предложить более оптимальный результат достижения Ваших
                целей.
              </p>
            </div>

            <div className="principles__box__item">
              <span className="principles__box__item__fig">3</span>
              <p className="principles__box__item__text">
                <b>Прозрачность ценовой политики</b> — стоимость услуг обсуждается в каждом
                конкретном случае и зависит от сложности и объема дела. Возможна
                поэтапная оплата работ.
              </p>
            </div>

          </div>
        </section>
  );
}

export default PrinciplesScreen;