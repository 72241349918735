import './index.scss';

const ConsultationScreen = () => {
  return (
    <section className="consultation">
          <h2 className="consultation__heading">Что вы получите на первой консультации</h2>

          <div className="consultation__box">
            <div className="consultation__box__item">
              <span className="consultation__box__item__fig">1</span>
              <p className="consultation__box__item__text">
                Подрбный анализ вашей ситуации и выяснение обстоятельств дела. Знакомство с документами и материалами.
              </p>
            </div>

            <div className="consultation__box__item">
              <span className="consultation__box__item__fig">2</span>
              <p className="consultation__box__item__text">
                Разъяснение перспектив дела, варианты решения проблемы.
              </p>
            </div>

            <div className="consultation__box__item">
              <span className="consultation__box__item__fig">3</span>
              <p className="consultation__box__item__text">
                Согласование стоимости и сроков работы.
              </p>
            </div>

          </div>
        </section>
  );
}

export default ConsultationScreen;