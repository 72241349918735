// import { Link } from "react-router-dom";

import AdvocatePhoto from '../../../../assets/img/other/photo.png';
import TgIco from "../../../../assets/tg-ico.svg";
import VkIco from "../../../../assets/vk-ico.svg";
import TenChatIco from "../../../../assets/ten-ico.png";

import './index.scss';

const MainScreen = () => {
  return (
    <div className="header">
      <div className="header__boxBcg2"></div>
      <div className="header__boxBcg"></div>
      <img className="header__heading__photo" src={AdvocatePhoto} alt="advocate" />

      <div className="header__nav">
        <div className="header__nav__flexItem">
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="#services">Услуги</a>
          </div>
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="#testimonails">Отзывы</a>
          </div>
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="#contacts">Контакты</a>
          </div>
          {/* <div className="header__nav__item">
            <Link className="header__nav__item__link" to="/posts">Полезные статьи</Link>
          </div> */}
        </div>
        <div className="header__nav__flexItem">
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="tel:+79272774195">+7 927 277 41 95</a>
          </div>
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="mailto:orson@inbox.ru">orson@inbox.ru</a>
          </div>
        </div>
        <div className="header__nav__flexItem">
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="https://vk.com/ryzhovnv" target="_blanc">
              <img className="header__nav__item__socioIco" src={VkIco} alt="vk"/>
            </a>
          </div>
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="https://t.me/rnv_adv" target="_blanc">
              <img className="header__nav__item__socioIco" src={TgIco} alt="tg"/>
            </a>
          </div>
          <div className="header__nav__item">
            <a className="header__nav__item__link" href="https://tenchat.ru/2570094" target="_blanc">
              <img className="header__nav__item__socioIco" src={TenChatIco} alt="tenChat"/>
            </a>
          </div>
        </div>
      </div>

    <div className="header__heading">
      <div className="header__heading__item">
        <h1 className="header__heading__item__heading header__heading__item__heading__title">Адвокат</h1>
        <h1 className="header__heading__item__heading">
          Рыжова Наталья Викторовна</h1>
        <p className="header__heading__item__text">
          Стаж работы адвокатом более 25 лет. Профессиональный подход при оказании юридической помощи позволит сформировать оптимальную позицию защиты 
          Ваших интересов. Гарантирую внимательное отношение к вашей ситуации и приемлемые цены.<br/><br/>На связи в любое время.
        </p>
      </div>
    </div>

    <div className="header__info">
      <div className="header__info__services">
      <div className='header__info__services__list'>
        {/* <details className='header__info__services__list__item'>  
            <summary><b>Представительство в Первом кассационном суде общей юрисдикции</b></summary>  
            <div className="header__info__services__list__item__text">
              В кассационном порядке рассматриваются жалобы и представления на вступившие в законную силу решения судов первой и апелляционной инстанций. 
              В ходе кассационного производства вышестоящий суд проверяет законность приговора и иного судебного решения.  
              Правом кассационного обжалования наделяются все лица, которые имеют непосредственный юридический интерес в конкретном деле или 
              являются официальными представителями соответствующих государственных органов.
              <br /><br />
              Участие адвоката обеспечит комплексную защиту Ваших интересов на всех стадиях судебного процесса. 
              <br /><br />
              Оказываю представительство интересов клиентов в в Первом кассационном суде общей юрисдикции. Подготовлю кассационную жалобу. Основная задача – это полный комплекс юридической помощи по демократичным ценам.
            </div>
          </details> */}
          <details className='header__info__services__list__item'>  
            <summary>Гражданское право</summary>  
            <div className="header__info__services__list__item__text">
              Гражданское право регулирует широкий круг общественных отношений. К ним относятся вопросы, касающиеся сделок, договоров, обязательств, 
              обеспечения обязательств (залог, поручительство и т.д.), ответственности (убытки, неустойка, моральный вред и т.д.), 
              неосновательного обогащения, права собственности, нематериальных благ и т.д.  Сфера гражданских правоотношений предоставляет сторонам 
              большую свободу в реализации своих прав, что в свою очередь может повлечь определенные противоречия и конфликты. 
              <br /><br />
              Участие адвоката обеспечит комплексную защиту Ваших интересов на всех стадиях судебного процесса. 
              <br /><br />
              Выполняю полный спектр услуг, связанных с гражданским делопроизводством. Обладаю богатым опытом практической работы по договорному 
              праву, в том числе спорам, вытекающим из договоров поставки, подряда, аренды, займа и т.д. Оказываю юридическую помощь при взыскании убытков, 
              неустойки, процентов за пользование чужими денежными средствами денежными средствами, признании недействительными сделок, применении 
              последствий недействительности сделок и т.д.
            </div>
          </details>
          <details className='header__info__services__list__item'>  
            <summary>Интеллектуальное право</summary>  
            <div className="header__info__services__list__item__text">
              Результаты интеллектуальной деятельности и средства индивидуализации являются
              интеллектуальной собственностью, которым законом предоставляется правовая охрана.
              <br /><br />
              В соответствии с ГК РФ защита исключительных прав на результаты интеллектуальной
              деятельности осуществляется путем признания права, о пресечении действий, нарушающих право
              - к лицу, совершающими такие действия, путем возмещения убытков.
              <br /><br />
              В случае нарушения прав автора их защита осуществляется, в частности, путем признания
              права, восстановления положения, существовавшего до нарушения права, пресечения действий,
              нарушающих право или создающих угрозу его нарушения, компенсации морального вреда,
              публикации решения суда о допущенном нарушении (ст.1251 ГК РФ).
            </div>
          </details>
          <details className='header__info__services__list__item'>  
            <summary>Семейный споры</summary>  
            <div className="header__info__services__list__item__text">
              Семейные споры это самая распространенная проблема в современной жизни. Очень редко супругам удается достигнуть компромисса 
              при разделе имущества, решении вопроса с кем из родителей будут проживать дети, размера денежных средств на содержание детей. 
              Причин тому достаточно — это и взаимные обиды и внутренние семейные конфликты, которые в конечном итоге приводят к затяжным судебным 
              разбирательствам.
            </div>
          </details>
          <details className='header__info__services__list__item'> 
            <summary>Жилищные споры</summary>  
            <div className="header__info__services__list__item__text">
              В жизни каждого человека могут возникнуть жилищные вопросы, которые возможно разрешить только в судебном порядке.
              <br /><br />
              Юридические услуги по вопросам жилищного права включают в себя представление интересов Доверителя в судебном 
              заседании при рассмотрении гражданского дела, заключение договора дарения жилого помещения, вселение и выселение, 
              выдел доли в жилом помещении при разделе совместно-нажитого имущества и иные виды юридической помощи.
            </div>
          </details>
          <details className='header__info__services__list__item'> 
            <summary>Наследственное право</summary>  
            <div className="header__info__services__list__item__text">
              В соответствии со статьей 1110 Гражданского кодекса РФ при наследовании имущество умершего (наследство, наследственное имущество) 
              переходит к другим лицам в порядке универсального правопреемства, то есть в неизменном виде как единое целое и в один и тот же момент, 
              если из правил настоящего Кодекса не следует иное.
              <br /><br />
              Оказываю услуги по консультирование по вопросам вступления в наследство, оказание юридической помощи при вступлении в наследство, 
              признание права собственности на долю в наследственном имуществе, обжалование действий (бездействия) нотариуса при оформлении наследства, 
              признание завещания недействительным и иные наследственные споры.
            </div>
          </details>
          <details className='header__info__services__list__item'> 
            <summary>Земельное право</summary>  
            <div className="header__info__services__list__item__text">
              Чаще всего земельные споры возникают при определении границ земельного участка, при признании права собственности 
              на земельный участок или его часть, изъятие земельного участка из чужого незаконного владения.
              <br /><br />
              Адвокат по земельным спорам поможет вам профессионально оказать юридическую помощь при возникновении конфликтной ситуации, 
              связанной с владением, пользованием и распоряжением земельного участка.
            </div>
          </details>
          {/* <details className='header__info__services__list__item'> 
            <summary>Уголовные дела</summary>  
            <div className="header__info__services__list__item__text">
              Добиться строгого исполнения закона при производстве уголовного дела, исключить возможность ложного обвинения, 
              защитить права подозреваемых, обвиняемых, потерпевших при рассмотрении уголовного дела в суде вам поможет 
              квалифицированный специалист, имеющий необходимый опыт в уголовном судопроизводстве.
              <br /><br />
              Применяя все возможные правовые инструменты адвокат поможет исключить в ряде случаев незаконное привлечение к уголовной 
              ответственности и вынесения справедливого приговора.
            </div>
          </details> */}
          <details className='header__info__services__list__item'> 
            <summary>Юридическое сопровождение сделок</summary>  
            <div className="header__info__services__list__item__text">
              Юридическое сопровождение предполагает юридическое консультирование по вопросам составления и проверки договоров.
              <br /><br />
              Выполняю полный спектр услуг, связанных с гражданским делопроизводством. Обладаю богатым опытом практической работы по договорному 
              праву, в том числе спорам, вытекающим из договоров поставки, подряда, аренды, займа и т.д. Оказываю юридическую помощь при взыскании убытков, 
              неустойки, процентов за пользование чужими денежными средствами денежными средствами, признании недействительными сделок, применении 
              последствий недействительности сделок и т.д.
            </div>
          </details>
          <details className='header__info__services__list__item'> 
            <summary>Оспаривание сделок в судах</summary>  
            <div className="header__info__services__list__item__text">
              Оспаривание сделки – это процедура, направленная на признание сделки недействительной. Если суд выносит решение о 
              признании сделки недействительной, все последствия этой сделки прекращаются.
              <br /><br />

              Для принятия решения в вашу пользу, перед обращением в суд необходимо надлежащим образом подготовить все документы, с 
              предоставлением суду всех необходимых доказательств, подтверждающих обоснованность предъявленных требований.
              <br /><br />

              Участие адвоката, при оспаривании сделок в суде, поможет Вам избежать многочисленных трудностей и ошибок, и с наименьшими 
              потерями добиться разрешения вашего спора.
            </div>
          </details>
          <details className='header__info__services__list__item'> 
            <summary>Услуги для юридических лиц</summary>  
            <div className="header__info__services__list__item__text">
              Юридическое сопровождение предполагает юридическое консультирование по вопросам, возникающим в ходе предпринимательской 
              деятельности, при принятии важных решений, связанных с заключением договоров купли-продажи недвижимости, аренды, мены, 
              правовой анализ документов, представление интересов Доверителя при переговорах, в судах и многое другое.
              <br /><br />
              Участие адвоката в решении сложных юридических вопросах поможет вам принять правильное и оптимальное решение применительно к вашей ситуации.
            </div>
          </details>
        </div>
        {/* <ul className="header__info__services__list">
          <li className="header__info__services__list__item">
            <a href="services_people.html" className="header__info__services__list__item__link"><b>Представительство в Первом кассационном суде общей юрисдикции</b></a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_people.html" className="header__info__services__list__item__link">Гражданское право</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_people.html#family" className="header__info__services__list__item__link">Семейный споры</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_people.html#house" className="header__info__services__list__item__link">Жилищные споры</a>
          </li> 
          <li className="header__info__services__list__item">
            <a href="services_people.html#inheritance" className="header__info__services__list__item__link">Наследственное право</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_people.html#land" className="header__info__services__list__item__link">Земельное право</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_people.html#crime" className="header__info__services__list__item__link">Уголовные дела</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_orgs.html#maintenance" className="header__info__services__list__item__link">Юридическое сопровождение сделок</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_orgs.html#contracts" className="header__info__services__list__item__link">Оспаривание сделок в судах</a>
          </li>
          <li className="header__info__services__list__item">
            <a href="services_orgs.html" className="header__info__services__list__item__link">Услуги для юридических лиц</a>
          </li>
        </ul> */}
        <div className="header__info__adv__free">
          <p className="margin-bottom-small">Звоните!</p>
          <div className="header__info__adv__tel">
            <a className="header__info__adv__tel__link" href="tel:+79272774195">+7 927 277 41 95</a>
          </div>
        </div>
      </div>

      <div className="header__info__desclimer">
        <p>
          <b>Регистрационный номер в реестре адвокатов Московской области - 50/7529</b>
          <br/>
          Адвокат коллегии адвокатов города Москвы «ЮрПрофи»
          <br/>
          ИНН Рыжовой Н. В. 645404565705
        </p>
      </div>

    </div>
    </div>
  );
}

export default MainScreen;
