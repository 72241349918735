import { useState } from 'react';

import './index.css';
import MainScreen from './Components/MainScreen';
import AboutScreen from './Components/AboutScreen';
import PrinciplesScreen from './Components/PrinciplesScreen';
import ServiceScreen from './Components/ServicesScreen';
import TestimonialScreen from './Components/TestimonialsScreen';
import ContactsScreen from './Components/ContactsScreen';
import Footer from './Components/Footer';
import PhisicsModal from './Modals/PhisicsModal';
import JurModal from './Modals/JurModals';
import AboutModal from './Modals/AboutModal';
import TGScreen from './Components/TGScreen';
import ConsultationScreen from './Components/Consultation';

function Index() {
  const [isPhisicsModalOpen, setIsPhisicsModalOpen] = useState(false);
  const [isJurModalOpen, setIsJurModalOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const togglePhisics = () => {
    setIsPhisicsModalOpen(!isPhisicsModalOpen);
  };

  const toggleJur = () => {
    setIsJurModalOpen(!isJurModalOpen);
  };

  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
  }

  return (
    <div className="App">
    { isPhisicsModalOpen ? <PhisicsModal handleClose={togglePhisics} /> : null }
    { isJurModalOpen ? <JurModal handleClose={toggleJur}/> : null }
    { isAboutOpen ? <AboutModal handleClose={toggleAbout} /> : null }
      <MainScreen />
      <main>
        <AboutScreen toggleAbout={toggleAbout} />
        <PrinciplesScreen />
        <TGScreen />
        <ServiceScreen showPhisics={togglePhisics} showJur={toggleJur} />
        <ConsultationScreen />
        <TestimonialScreen />
        <ContactsScreen />
      </main>
      <Footer />
    </div>
  );
}

export default Index;
