import CloseIco from '../../../../assets/close.svg';

import './index.scss';

const JurModal = ({ handleClose }) => {
  return (
    <div className="overlay">
      <div className="modal">
      <img src={CloseIco} alt="CloseBtn" className='closeBtn' onClick={handleClose} />
      <main className="aboutAdv">
    <h3 className="servicePage__heading">Участие в переговорах.</h3>
    <p className="servicePage__text">
      Ведение деловых переговоров на стадии заключения сделок это залог успеха каждого предпринимателя и бизнесмена.
      <br /><br />
      Довольно часто деловые переговоры проходят на стадии досудебного урегулирования спора и помощь специалиста поможет 
      вам отстоять собственные права и интересы, найти более оптимальное решение вашей проблемы.
    </p>
    <h3 className="servicePage__heading">Оспаривание сделок в судах.</h3>
    <div className="servicePage__text">
      Сделками признаются действия граждан и юридических лиц, направленные на установление, изменение или прекращение гражданских прав и обязанностей.
      <br /><br />
      Сделка, нарушающая требования закона или иного правового акта, является оспоримой,
      <br /><br />
      Сделка, нарушающая требования закона или иного правового акта и при этом посягающая на публичные интересы либо права и 
      охраняемые законом интересы третьих лиц, ничтожна.
      <br /><br />

      Оспаривание сделки – это процедура, направленная на признание сделки недействительной. Если суд выносит решение о 
      признании сделки недействительной, все последствия этой сделки прекращаются.
      <br /><br />

      Для принятия решения в вашу пользу, перед обращением в суд необходимо надлежащим образом подготовить все документы, с 
      предоставлением суду всех необходимых доказательств, подтверждающих обоснованность предъявленных требований.
      <br /><br />

      Участие адвоката, при оспаривании сделок в суде, поможет Вам избежать многочисленных трудностей и ошибок, и с наименьшими 
      потерями добиться разрешения вашего спора.
    </div>
    <h3 className="servicePage__heading">Интеллектуальное право.</h3>
    <div className="servicePage__text">
      Интеллектуальное право охраняет результаты умственного творческого труда авторов и приравненные к ним средства индивидуализации 
      (коммерческие обозначения, фирменные наименования, товарные знаки и т.п.) считаются объектами интеллектуальной собственности. 
      На них распространяются нормы интеллектуального право описанные в Гражданском Кодексе РФ и прочи нормативных документов. 
      Такие права являются сочетанием исключительного (имущественного) права, личных неимущественных и иных прав.
      <br /><br />
      Согласно статье 1225 Гражданского кодекса интеллектуальная собственность – это охраняемые законом 
      результаты интеллектуальной деятельности и средства индивидуализации.

    </div>
    <h3 className="servicePage__heading">Разрешение корпоративных споров.</h3>
    <div className="servicePage__text">
      Корпоративный спор – это спор, связанный с созданием юридического лица, управлением им или участием в 
      юридическом лице, который является коммерческой организацией, а также некоммерческой организацией, 
      объединяющей коммерческие организации и (или) индивидуальных предпринимателей.
      <br /><br />

      Корпоративные споры рассматриваются в Арбитражном суде и затрагивают различные категории юридических лиц.
      <br /><br />

      Участие адвоката в досудебном урегулировании корпоративных споров, поможет Вам избежать многочисленных 
      трудностей и ошибок и с наименьшими потерями добиться разрешения вашего спора.
      <br /><br />
      Самыми распространенными корпоративными спорами являются:
      <ul className="servicePage__list">
        <li>учреждение, ликвидация или реорганизация юридического лица;</li>
        <li>реализация преимущественных прав участников при продаже долей организации;</li>
        <li>оспаривание сделок в суде, совершаемых обществом.</li>
      </ul>
    </div>
    <h3 className="servicePage__heading">Юридическое сопровождение.</h3>
    <div className="servicePage__text">
      Юридическое сопровождение предполагает юридическое консультирование по вопросам, возникающим в ходе предпринимательской 
      деятельности, при принятии важных решений, связанных с заключением договоров купли-продажи недвижимости, аренды, мены, 
      правовой анализ документов, представление интересов Доверителя при переговорах, в судах и многое другое.
      <br /><br />
      Участие адвоката в решении сложных юридических вопросах поможет вам принять правильное и оптимальное решение применительно к вашей ситуации.
    </div>

  </main>
      </div>
    </div>
  )
}

export default JurModal;
