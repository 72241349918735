import './index.scss';

const TestimonialScreen = () => {
  return (
    <section className="testimonails" id="testimonails">
      <h2 className="testimonails__heading">Отзывы</h2>
      <div className="testimonails__layout">
        <div className="testimonails__box">
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Ирина</h3>
            <p className="testimonails__box__item__text">
              Хочу поблагодарить адвоката Рыжову Н.В. благодаря которой в суде удалось устранить препятствия в пользовании принадлежавшим 
              мне земельным участком, на котором соседи самовольно установили гараж.
            </p>
            <p className="testimonails__box__item__text">
              С уважением Ирина!
            </p>
          </div>

          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Быстрова Елена</h3>
            <p className="testimonails__box__item__text">
              В 2014 году умер мой отец. После смерти папы открылось наследство, а с ним и вселенского масштаба проблемы, с которыми 
              мы столкнулись при выяснении состава имущества, которое принадлежало моему отцу. 
            </p>
            <p className="testimonails__box__item__text">
              Сначала появилась «иждивенка», которая обратилась в Тверской районный суд с иском к наследникам, пытаясь доказать, 
              что якобы она в течении года до смерти моего отца, совместно с ним проживала и находилась у него на иждивении и по ее мнению 
              имела право на долю в наследстве.
            </p>
            <p className="testimonails__box__item__text">
              Благодаря грамотно выбранной Натальей Викторовной позиции по делу, мы доказали, что «иждивенка» в последний год жизни 
              моего отца с ним совместно не проживала и на содержании у него не находилась. 
            </p>
            <p className="testimonails__box__item__text">
              Наталья Викторовна, я выражаю Вам искреннюю благодарность за Ваш профессиональный подход, за  качественную работу, за проделанный огромный объем работы в моем деле.
            </p>
          </div>
  
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Олег Петрович</h3>
            <p className="testimonails__box__item__text">
              Остался очень доволен результатом работы адвоката Рыжовой Н.В., которая грамотно составила исковое заявление о признании 
              недействительным свидетельства о праве на наследство и признании за мной права собственности на долю в квартире. 
              Иск был полностью удовлетворен.
            </p>
            <p className="testimonails__box__item__text">
              Большое Вам спасибо!
            </p>
          </div>
  
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Зюбина Светлана.</h3>
            <p className="testimonails__box__item__text">
              После расторжения брака бывший муж не поставив меня в известность начал продавать квартиру, 
              которая была приобретена нами в период брака. И только благодаря профессиональным действиям 
              адвоката Рыжовой Н.В. в суде признали моё право собственности на половину квартиры и был произведен раздел всего имущества.
            </p>
          </div>
  
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Борис Денисов</h3>
            <p className="testimonails__box__item__text">
              Большое спасибо адвокату Рыжовой Н.В. которая помогла мне через суд признать за мной право собственности на квартиру 
              в объекте незавершенного строительства.
            </p>
          </div>
  
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Елена Геннадьевна</h3>
            <p className="testimonails__box__item__text">
              Я очень долго ходила в страховую компанию за получением страховой выплаты, в которой с меня постоянно требовали 
              очередные документы и только благодаря адвокату Рыжовой Н.В. в судебном порядке удалось взыскать со 
              страховой компании в мою пользу страховую выплату.
            </p>
            <p className="testimonails__box__item__text">
              Огромное спасибо!
            </p>
          </div>
  
          <div className="testimonails__box__item">
            <h3 className="testimonails__box__item__header">Дмитрий Юрьевич</h3>
            <p className="testimonails__box__item__text">
              Обращаюсь за помощью к адвокату уже долгое время. Первый раз по поводу раздела имущества, затем были дела по земельным спорам, взыскание зарплаты и долгов.
              Каждый раз получал очень грамотную помощь. При взыскании зарплаты, я долгое время выпрашивал свои деньги, но обратившись к адвокату получил свои деньги очень быстро.
              
            </p>
            <p className="testimonails__box__item__text">
              Большое спасибо!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialScreen;
